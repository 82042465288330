
<template>
  <div  @click="showSelect = false">
    <div class="form">
      <el-form :model="form" :inline="true">
        <el-form-item label="统计方式">
          <el-select v-model="form.x">
            <el-option label="日统计" value="1"></el-option>
            <el-option label="月统计" value="2"></el-option>
            <el-option label="年统计" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择门店">
          <el-select
            v-model="form.hierarchy_id"
            filterable
            :disabled="userInfo.hierarchy_type_id === 40"
            @change="changeSelect"
          >
            <template v-for="item in merchantList">
              <el-option
                :value="item.hierarchy_id"
                :label="item.hierarchy_name"
                :key="item.hierarchy_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="select" @click.stop="handleChange">
            <span v-if="currTime.field === 'times'">
              {{ currTime.title[0] }}
              <span style="margin: 0 10px">至</span>
              {{ currTime.title[1] }}
            </span>
            <span v-else>{{ currTime.title }}</span>
            <i
              class="el-select__caret el-input__icon el-icon-arrow-down"
              :class="showSelect ? 'up' : ''"
            ></i>
            <div class="triangle" v-show="showTriangle"></div>
            <div
              class="box"
              @click.stop=""
              :style="
                showSelect
                  ? { height: '365px', boxShadow: '2px 5px 5px #ccc' }
                  : {}
              "
            >
              <div class="list">
                <div class="item" v-for="item in timeList" :key="item.id">
                  <span>{{ item.title }}</span>
                  <template v-for="_ in item.arr">
                    <div
                      :key="_.field"
                      :style="
                        _.field === 'last_quarter' ? { marginLeft: '17px' } : {}
                      "
                      @click="chooseTime(_)"
                      :class="dropdownCurr.field === _.field ? 'active' : ''"
                    >
                      {{ _.title }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="time">
                <div style="text-indet: 20px">自定义</div>
                <el-date-picker
                  @change="handlePicker"
                  v-model="times"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  value-format="yyyy-MM-dd"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <div class="bottom">
                <el-checkbox v-model="isDefault">保存为默认值</el-checkbox>
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  style="width: 60px"
                  @click="handleTrue"
                  >确定</el-button
                >
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- <el-form-item label="统计时间">
          <el-date-picker
            v-model="form.value1"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <!-- <el-form-item>
          <el-button type="primary">查询</el-button>
          <el-button type="primary" plain>重置</el-button>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="chart1">
      <div class="btns">
        <div :class="type === 1? 'btns-active' : ''" @click="changeType(1)">领取使用</div>
        <div style="margin-left: 20px" :class="type === 2? 'btns-active' : ''"  @click="changeType(2)">销售转化</div>
      </div>
      <div id="chart1"></div>
    </div>
    <div class="static-box">
      <div class="static">
        <div class="static-head">发放统计</div>
        <div
          style="display: flex; justify-content: space-around; margin-top: 36px"
        >
          <div>
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              {{analyzNum.give_total_num || 0}}
            </div>
            <div style="text-align: center; color: #565656">已发放</div>
          </div>
          <div v-if="row.card_type === 10">
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              <span style="font-size: 20px">￥</span>{{analyzNum.give_total_price || 0}}
            </div>
            <div style="text-align: center; color: #565656">发放金额</div>
          </div>
          <div>
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              {{analyzNum.give_total_user || 0}}
            </div>
            <div style="text-align: center; color: #565656">领取人数</div>
          </div>
        </div>
      </div>
      <div class="static" style="margin-left: 40px">
        <div class="static-head">使用统计</div>
        <div
          style="display: flex; justify-content: space-around; margin-top: 36px"
        >
          <div>
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              {{analyzNum.use_total_num || 0}}
            </div>
            <div style="text-align: center; color: #565656">已使用</div>
          </div>
          <div>
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              <!-- <span style="font-size: 20px">￥</span> -->
              {{analyzNum.use_person || 0}}%
            </div>
            <div style="text-align: center; color: #565656">
              <!-- <i
                class="el-icon-warning-outline"
                style="color: #f56c6c; font-size: 13px"
              ></i> -->
              百分比
            </div>
          </div>
          <div>
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              {{analyzNum.use_total_user || 0}}
            </div>
            <div style="text-align: center; color: #565656">使用人数</div>
          </div>
        </div>
      </div>
      <div class="static" style="margin-left: 40px">
        <div class="static-head">转化订单</div>
        <div
          style="display: flex; justify-content: space-around; margin-top: 36px"
        >
          <div>
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              {{analyzNum.total_order_price || 0}}
            </div>
            <div style="text-align: center; color: #565656">订单总额</div>
          </div>
          <div>
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              <span style="font-size: 20px">￥</span>{{analyzNum.order_num || 0}}
            </div>
            <div style="text-align: center; color: #565656">
              <!-- <i
                class="el-icon-warning-outline"
                style="color: #f56c6c; font-size: 13px"
              ></i> -->
              订单(笔)
            </div>
          </div>
          <div>
            <div style="font-size: 32px; color: #565656; font-weight: bold; text-align: center;">
              <span style="font-size: 20px">￥</span>{{analyzNum.total_benefit_price || 0}}
            </div>
            <div style="text-align: center; color: #565656">券优惠金额</div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart2">
      <div class="head">领取占比分析</div>
      <div style="display: flex">
        <div classs="left">
          <el-table
            height="350px"
            :data="tableData1.list"
            v-loading="loading1"
            :default-sort="{ prop: 'date', order: 'descending' }"
            style="border: 1px solid #ddd; margin-top: 20px; width: 671px"
            border
            stripe
          >
            <template v-for="item in columns1">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :sortable="item.sortable"
                :width="item.t_width"
              >
                <template slot-scope="scope">
                  <template v-if="item.field_alias === 'get_way'">
                    <span v-if="scope.row.get_way == 10">手动发放</span>
                    <span v-if="scope.row.get_way == 20">注册发放</span>
                  </template>
                  <template v-else-if="item.field_alias === 'percent'">
                    {{scope.row.percent}}%
                  </template>
                  <template v-else-if="item.field_alias === 'use_precent'">
                    {{scope.row.use_precent}}%
                  </template>
                  <template v-else>
                    {{
                      scope.row[item.field_alias]
                        ? scope.row[item.field_alias]
                        : "--"
                    }}
                  </template>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div class="right">
          <div id="chart2"></div>
          <div class="total" v-if="tableData1.total_num">领取总数：{{tableData1.total_num}}</div>
        </div>
      </div>
    </div>
    <div class="chart3">
      <div class="head">转化占比分析</div>
      <div style="display: flex">
        <div classs="left">
          <el-table
            height="350px"
            :data="tableData2.list"
            v-loading="loading2"
            :default-sort="{ prop: 'date', order: 'descending' }"
            style="border: 1px solid #ddd; margin-top: 20px; width: 671px"
            border
            stripe
          >
            <template v-for="item in columns2">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :sortable="item.sortable"
                :width="item.t_width"
              >
                <template slot-scope="scope">
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div class="right">
          <div id="chart3"></div>
          <div class="total" v-if="tableData2.total_order_price">订单总额：{{tableData2.total_order_price}}</div>
        </div>
      </div>
    </div>
    <div class="chart3">
      <div class="head">用券商品分析</div>
      <div class="form">
        <el-form :model="form2" :inline="true">
          <el-form-item label="商品名称">
            <el-input placeholder="请输入商品名称" v-model="form2.goods_name" clearable></el-input>
          </el-form-item>
          <el-form-item label="品类">
            <SelectTree
              v-if="dialogVisible"
              :value="valueId"
              :props="props"
              :options="select"
              :clearable="isClearable"
              :accordion="isAccordion"
              @getValue="getValue($event)"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="page = 1; getGoodsAnalyze();">查询</el-button>
            <el-button type="primary" plain @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="border: 1px solid #ccc">
        <el-table
          :data="tableData3.list"
          style="width: 100%"
          height="400"
          stripe
          v-loading="loading3"
          :row-class-name="handleIndex"
        >
          <template v-for="item in columns3">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                {{
                  scope.row[item.field_alias]
                    ? scope.row[item.field_alias]
                    : "--"
                }}
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="$store.state.pageSizes"
          :page-size="$store.state.pageSizes[0]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData3.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Line, Pie } from "@antv/g2plot";
import { getMerchantListReq } from "@/api/goods/historyInv/index";
import { getDefaultReq, setDefultReq, saleLineReq, analyzingReq, salePieReq, drawPieReq, goodsAnalyzeReq } from "@/api/marketing/coupon";
import { getListReq } from "@/api/goods/goodsConfig/classify";
import storage from "good-storage";

export default {
  data() {
    return {
      isClearable: false, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      select: [],
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      valueId: 0,
      dialogVisible: false,
      analyzNum: {}, // 分析数据
      type: 1,
      showSelect: false,
      showTriangle: false, // 展示三角形
      times: [],
      isDefault: false,
      currTime: { title: "", field: "" },
      dropdownCurr: { title: "", field: "" }, // 下拉框当前点击，当点击确定时赋值给currTime
      timeList: [
        {
          id: 0,
          title: "天",
          arr: [
            { title: "今天", field: "today" },
            { title: "昨天", field: "yesterday" },
          ],
        },
        {
          id: 1,
          title: "周",
          arr: [
            { title: "本周", field: "week" },
            { title: "上周", field: "last_week" },
          ],
        },
        {
          id: 2,
          title: "月",
          arr: [
            { title: "本月", field: "month" },
            { title: "上月", field: "last_month" },
          ],
        },
        {
          id: 3,
          title: "季",
          arr: [
            { title: "本季度", field: "this_quarter" },
            { title: "上一季度", field: "last_quarter" },
          ],
        },
        {
          id: 4,
          title: "年",
          arr: [
            { title: "今年", field: "year" },
            { title: "去年", field: "last_year" },
          ],
        },
      ],
      userInfo: {}, // 用户信息
      merchantList: [], // 门店列表
      page: 1,
      limit: this.$store.state.pageSizes[0],
      form2: {},
      columns3: [
        { field_text: "序号", field_alias: "index", t_width: '100' },
        { field_text: "商品图片", field_alias: "goods_pic" },
        { field_text: "商品名称", field_alias: "goods_name" },
        { field_text: "品类", field_alias: "goods_type_name" },
        { field_text: "销售总量", field_alias: "total_num" },
        { field_text: "销售总额(元)", field_alias: "total_sale_price" },
        { field_text: "券优惠总金额", field_alias: "total_benefit_price" },
        { field_text: "优惠券使用数量", field_alias: "total_num1" },
        { field_text: "使用占比", field_alias: "percent" },
      ],
      columns2: [
        { field_text: "排名", field_alias: "index" },
        { field_text: "门店", field_alias: "index1" },
        { field_text: "订单总额", field_alias: "index2", },
        { field_text: "占比", field_alias: "index3",},
        { field_text: "券优惠金额", field_alias: "index4",},
      ],
      columns1: [
        { field_text: "获取渠道", field_alias: "get_way" },
        { field_text: "领取数量", field_alias: "total_num",},
        { field_text: "占比", field_alias: "percent",},
        { field_text: "使用数量", field_alias: "use_num",},
        { field_text: "使用率", field_alias: "use_precent",},
      ],
      loading3: false,
      loading2: false,
      loading1: false,
      tableData1: {
        list: [],
        total_num: 0,
      },
      tableData2: {
        list: [],
        total_order_price: 0,
      },
      tableData3: {
        list: [],
        total: 0,
      },
      form: {},
      rules: {},
      chart1: null,
      chart2: null,
      chart3: null,
    };
  },
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getGoodsTypeList();

    // promise等两个接口返回
    Promise.all([getMerchantListReq(), getDefaultReq()])
      .then((res) => {
        if (res[0].code === 1) {
          this.merchantList = res[0].data;
          // 如果是公司
          if (this.userInfo.hierarchy_type_id === 30) {
            this.merchantList.unshift({
              hierarchy_id: 0,
              hierarchy_name: "全部门店",
            });
          }
          this.form = {
            ...this.form,
            hierarchy_id: this.merchantList[0].hierarchy_id,
          };
        }
        if (res[1].code === 1) {
          this.timeList.forEach((item) => {
            item.arr.forEach((_) => {
              if (_.field === res[1].data.time_type) {
                this.currTime = _;
                this.dropdownCurr = _;
              }
            });
          });
        }
        this.drawChart1();
        this.getAnalyzing();
        this.drawChart2();
        this.drawChart3();
        this.getGoodsAnalyze();
      })
      .catch((err) => {
        console.log(err);
        this.$message.error('请求失败');
      })
  },
  watch: {
    showSelect: {
      handler(val) {
        if (!val) {
          setTimeout(() => {
            this.showTriangle = false;
          }, 200);
        } else {
          this.showTriangle = true;
        }
      },
    },
  },
  methods: {
    handleIndex({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },

    // 优惠券商品使用占比分析
    getGoodsAnalyze() {
      this.loading3 = true;
      goodsAnalyzeReq({
        time: this.currTime.field,
        hierarchy_id: this.form.hierarchy_id,
        store_card_id: this.row.store_card_id,
        ...this.form2,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          this.loading3 = false;
          if (res.code === 1) {
            res.data.list.forEach((item) => {
              item.percent = '100%';
              item.total_num1 = item.total_num;
            })
            this.tableData3 = res.data;
          }
        })
        .catch(() => {
          this.loading3 = false;
        });
    },

    resetForm() {
      this.valueId = 0;
      this.form2 = {
        ...this.form2,
        goods_type_id: this.valueId,
        goods_name: '',
      };
      this.page = 1;
      this.getGoodsAnalyze();
    },

    // 取值
    getValue(value) {
      this.valueId = value;
      this.form2 = {
        ...this.form2,
        goods_type_id: this.valueId,
      };
    },
    /*
     *   获取品类下拉数据
     */
    getGoodsTypeList() {
      getListReq().then((res) => {
        if (res.code === 1) {
          res.data.unshift({
            goods_type_id: 0,
            goods_type_name: '全部品类',
          })
          this.select = res.data;
          this.dialogVisible = true;
        }
      });
    },
    
    // 优惠券分析线性趋势图分析
    getAnalyzing() {
      analyzingReq({
        time: this.currTime.field,
        hierarchy_id: this.form.hierarchy_id,
        store_card_id: this.row.store_card_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.analyzNum = res.data;
          }
        })
    },

    changeSelect() {
      this.drawChart1();
    },

    changeType(type) {
      this.type = type;
      this.drawChart1();
    },

    // 下拉框选择时间
    chooseTime(curr) {
      this.dropdownCurr = curr;
      this.times = [];
    },
    
    // 设置默认时间
    setDefult() {
      setDefultReq({ time_type: this.currTime.field })
        .then((res) => {
          if (res.code === 1) {};
        });
    },

    handleTrue() {
      this.currTime = this.dropdownCurr;
      this.showSelect = false;
      if (this.isDefault) {
        this.setDefult();
      }
      this.drawChart1();
      this.getAnalyzing();
      this.drawChart2();
      this.drawChart3();
    },

    handlePicker() {
      this.dropdownCurr = {
        field: 'times',
        title: this.times,
      };
    },

    handleChange() {
      this.showSelect = !this.showSelect;
    },

    handleCurrentChange(page) {
      this.page = page;
      this.getGoodsAnalyze();
    },

    handleSizeChange(limit) {
      this.limit = limit;
      this.getGoodsAnalyze();
    },

    async drawChart3() {
      const result = await salePieReq({
        time: this.currTime.field,
        hierarchy_id: this.form.hierarchy_id,
        store_card_id: this.row.store_card_id,
      })
      let data = [];
      if (result.code === 1) {
        data = result.data.list.map((item) => {
          return {
            ...item,
            percent: Number(item.percent),
          }
        });
      }
      this.tableData2 = {
        list: data,
        total_order_price: result.data.total_order_price,
      };
      if (this.chart3) {
        this.chart3.destroy();
      }
      this.chart3 = new Pie("chart3", {
        appendPadding: 10,
        data,
        angleField: "total_order_price",
        colorField: "hierarchy_name",
        radius: 0.75,
        label: {
          type: "inner",
          labelHeight: 28,
          content: "{name}\n{percentage}",
        },
        interactions: [
          { type: "element-selected" },
          { type: "element-active" },
        ],
      });

      this.chart3.render();
    },

    async drawChart2() {
      const result = await drawPieReq({
        time: this.currTime.field,
        hierarchy_id: this.form.hierarchy_id,
        store_card_id: this.row.store_card_id,
      })
      let data = [];
      if (result.code === 1) {
        data = result.data.list.map((item) => {
          return {
            ...item,
            get_way_text: item.get_way == 10 ? '手动发放' : (item.get_way === 20 ? '注册发放' : ''),
            percent: Number(item.percent),
          }
        });
      }
      this.tableData1 = {
        list: data,
        total_num: result.data.total_num,
      };
      if (this.chart2) {
        this.chart2.destroy();
      }
      this.chart2 = new Pie("chart2", {
        appendPadding: 10,
        data,
        angleField: "total_num",
        colorField: "get_way_text",
        radius: 0.75,
        label: {
          type: "inner",
          labelHeight: 28,
          content: "{name}\n{percentage}",
        },
        interactions: [
          { type: "element-selected" },
          { type: "element-active" },
        ],
      });

      this.chart2.render();
    },

    async drawChart1() {
      const result = await saleLineReq({
        time: this.currTime.field,
        hierarchy_id: this.form.hierarchy_id,
        store_card_id: this.row.store_card_id,
        type: this.type,
      });
      let data = [];
      if (result.code === 1) {
        data = result.data;
      }
      if (this.chart1) {
        this.chart1.destroy();
      }
      this.chart1 = new Line("chart1", {
        data,
        xField: "day",
        yField: "total_price",
        seriesField: "type",
        // xAxis: {
        //   type: "time",
        // },
      });
      this.chart1.render();
    },
    
    goBack() {
      this.$emit("onBack");
    },
  },
};
</script>

<style lang="less" scoped>
.overflow {
  height: calc(100vh - 90px);
  overflow: hidden;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 2;
}
.iconfont:hover {
  color: #409eff !important;
}
.chart-list {
  .block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .chart {
      width: 49.6%;
      background: white;
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      margin-top: 10px;
      &-top {
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        .left {
          font-weight: bold;
          text-indent: 10px;
          font-size: 18px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 17px;
            top: 9px;
            left: 0;
            border-radius: 2px;
            background: #409eff;
          }
        }
        .right {
          line-height: 32px;
          .tag {
            margin-left: 10px;
          }
        }
      }
      .flex-box {
        margin-top: 15px;
        height: calc(100% - 47px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          height: 48%;
          .item {
            width: 32%;
            background: rgb(248, 251, 254);
            text-align: center;
            height: 100%;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .num {
              font-size: 32px;
              font-weight: bold;
              margin-bottom: 1%;
            }
          }
        }
      }
      .draw {
        margin-top: 15px;
        height: calc(100% - 47px);
      }
    }
  }
}
.triangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  position: absolute;
  top: 42px;
  left: 27px;
  z-index: 9;
}
.box {
  background: white;
  position: absolute;
  top: 48px;
  left: -88px;
  height: 0px;
  overflow: hidden;
  transition: all 0.2s;
  z-index: 999;
}
/deep/ .el-date-editor--daterange.el-input__inner {
  width: 290px;
}
/deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
  background: none;
}
/deep/ .el-dropdown-menu__item {
  padding: 0;
}
.time {
  padding: 10px 10px;
  background: rgb(240, 240, 240);
  div {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.bottom {
  display: flex;
  align-items: center;
  background: white;
  padding: 10px 20px;
  justify-content: space-between;
}
.select {
  color: #606266;
  cursor: pointer;
  background: white;
  padding: 0 10px 0 15px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  margin-left: 10px;
  position: relative;
  .el-icon-arrow-down {
    color: #c0c4cc;
    cursor: pointer;
    margin-left: 10px;
    transform: rotateZ(0deg);
  }
  .up {
    transform: rotateZ(180deg);
  }
}
.list {
  width: 260px;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;
  font-size: 12px;
  .item {
    display: flex;
    line-height: 23px;
    .active {
      background: #409eff;
      color: white !important;
    }
    span {
      padding: 5px 10px;
      color: #909399;
      cursor: pointer;
    }
    div {
      padding: 5px 10px;
      cursor: pointer;
      margin-left: 30px;
    }
    div:hover {
      color: #409eff;
    }
  }
}
.form {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(243, 249, 255, 1) 100%
  );
}
.chart3 {
  background: white;
  margin-top: 10px;
  padding: 20px;
  .right {
    margin-left: 15%;
    position: relative;
    .total {
      position: absolute;
      color: #565656;
      font-size: 20px;
      font-weight: 700;
      bottom: 36px;
      width: 400px;
      text-align: center;
    }
  }
  #chart3 {
  }
  .head {
    color: #333333;
    font-weight: bold;
    text-indent: 10px;
    position: relative;
    &::before {
      content: "";
      width: 4px;
      height: 14px;
      position: absolute;
      background: #1991ff;
      top: 4px;
      left: 0;
      border-radius: 4px;
    }
  }
}
.chart2 {
  background: white;
  margin-top: 10px;
  padding: 20px;
  .right {
    margin-left: 15%;
    position: relative;
    .total {
      position: absolute;
      color: #565656;
      font-size: 20px;
      font-weight: 700;
      bottom: 36px;
      width: 400px;
      text-align: center;
    }
  }
  #chart2 {
  }
  .head {
    color: #333333;
    font-weight: bold;
    text-indent: 10px;
    position: relative;
    &::before {
      content: "";
      width: 4px;
      height: 14px;
      position: absolute;
      background: #1991ff;
      top: 4px;
      left: 0;
      border-radius: 4px;
    }
  }
}
.static-box {
  margin-top: 10px;
  display: flex;
  .static {
    background: white;
    width: 27%;
    height: 182px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 6px;
    &-head {
      color: #333333;
      font-weight: bold;
      text-indent: 10px;
      position: relative;
      &::before {
        content: "";
        width: 4px;
        height: 14px;
        position: absolute;
        background: #1991ff;
        top: 4px;
        left: 0;
        border-radius: 4px;
      }
    }
  }
}
.chart1 {
  padding: 20px;
  background: white;
  border-radius: 6px;
  #chart1 {
    width: 95%;
  }
  .btns {
    display: flex;
    margin-bottom: 20px;
    &-active {
      color: #1991ff !important;
    }
    div {
      font-size: 16px;
      color: #565656;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.top {
  background: white;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  margin-bottom: 10px;
}
.form {
  margin-top: 10px;
  height: 64px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(243, 249, 255, 1) 100%
  );
  padding: 12px 20px;
  box-sizing: border-box;
}
</style>
